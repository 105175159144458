import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import { SearchBar, SpecialistsTails } from "page_components/specialists"

let filtered_specialists = []

const Team = ({ data }) => {
  const [currentCategory, setCurrentCategory] = useState(null)
  const [specName, setSpecName] = useState(null)

  const breadcrumbs_data = [
    {
      name: "Zespół",
      href: "/zespol/",
    },
  ]

  if (specName) {
    filtered_specialists = data?.allWpSpecialist?.nodes?.filter(
      specialist => specialist.acfSpecialists.name === specName
    )
  } else {
    if (currentCategory === null) {
      filtered_specialists = data?.allWpSpecialist?.nodes
    } else {
      filtered_specialists = data?.allWpSpecialist?.nodes?.filter(specialist =>
        specialist.specializations.nodes.some(e => e.name === currentCategory)
      )
    }
  }

  return (
    <Layout
      seo={{
        title: "Zespół",
        description:
          "Specjalizujemy się wyłącznie w podologii i stale poszerzamy wiedzę w tej dziedzinie. Mamy zespół z najlepszymi kwalifikacjami",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Zespół" />
      <SearchBar
        filtered_specialists={filtered_specialists}
        specializations={data?.allWpSpecialization?.nodes}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        specName={specName}
        setSpecName={setSpecName}
      />
      <SpecialistsTails specialists={filtered_specialists} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpSpecialist(sort: { fields: date, order: DESC }) {
      nodes {
        id
        slug
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        specializations {
          nodes {
            id
            name
          }
        }
        acfSpecialists {
          booking
          name
          shortDesc
          specDesc
          specTitle
        }
      }
    }
    allWpSpecialization {
      nodes {
        id
        name
      }
    }
  }
`

export default Team
